import BlockMaxWidth from '@/modules/layout/components/BlockMaxWidth';

import styles from "./styles.module.scss";
import PublicIcons from '@/components/basic/PublicIcon';
import useClientContext from '@/context/ClientContext';
import FormSubscribe from '@/modules/layout/components/Footer/forms/FormSubscribe';

const FooterSubscribe = () => {
  const { translate } = useClientContext();
  return (
    <div className={styles.subscribeWrapper}>
      <BlockMaxWidth className={styles.subscribeBlock}>
        <div className={styles.subscribeLabel}>
          {/* <PublicIcons name="subscribe" width={44} height={48} /> */}
          <p>
            {translate("Subscribe to the newsletter about new promotions and sales!")}
          </p>
        </div>
        <FormSubscribe />
      </BlockMaxWidth>
    </div>
  )
}

export default FooterSubscribe;
