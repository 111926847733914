"use client";
import { useCallback, useEffect, useRef, useState } from "react";
import cn from "@/libs/cn";
import { QUERY_SEARCH_PARAM_NAME } from "@/constants/common";
import LoaderCircle from "@/controls/LoaderCircle";
import PublicIcons from "@/components/basic/PublicIcon";
import useClientContext from "@/context/ClientContext";
import Button from "@/controls/Button";
import routesPublic from "@/modules/public/constants/routesPublic";
import useClientRouter from "@/hooks/useClientRouter";
import { CONTENT_SELECTOR_ID } from "../Content";
import InlineIcons from "@/components/basic/InlineIcons";

import styles from "./styles.module.scss";

const MIN_SEARCH_LENGTH = 3;

const IconSearch = (iconProps: any) => <InlineIcons.Search width={24} height={24} {...iconProps} />;

interface ButtonGlobalSearch {
  paramName?: string;
  addQuery?: { [param: string]: string };
  mobile?: boolean;
}

const ButtonGlobalSearch = (props: ButtonGlobalSearch) => {
  const { paramName = QUERY_SEARCH_PARAM_NAME, addQuery = {}, mobile } = props;
  const { isMobileSize } = useClientContext();
  const router = useClientRouter();
  const { query } = router;
  const { translate, toast, locale } = useClientContext();
  const ref = useRef<HTMLInputElement>(null);
  const [searchStr, setSearchStr] = useState(query[paramName] || "");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (loading) {
      setLoading(false);
    }

    if (searchStr && typeof query[paramName] === "undefined") {
      setSearchStr("");
    }

    if (searchStr !== query[paramName]) {
      setSearchStr(query[paramName] || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query?.[paramName]]);

  const [focus, setFocus] = useState(false);
  useEffect(() => {
    const contentElem = document.querySelector(`#${CONTENT_SELECTOR_ID}`);

    if (!isMobileSize || !mobile) {
      contentElem?.classList.remove(styles.contentBlur);
      return;
    }

    if (focus) {
      contentElem?.classList.add(styles.contentBlur);
    } else {
      contentElem?.classList.remove(styles.contentBlur);
    }
  }, [isMobileSize, focus, mobile]);

  const onChange = useCallback((e: any) => {
    const val = e.target.value;
    setSearchStr(val);
  }, []);

  const checkValue = useCallback(
    (val: string): boolean => {
      if(!val && !query?.[paramName]) {
        return false;
      }
      if (val === query?.[paramName]) {
        return false;
      }

      if (typeof val?.length === "string" && val.length < MIN_SEARCH_LENGTH) {
        toast.info(
          translate("The minimum length of a phrase to search") + ` ${MIN_SEARCH_LENGTH} ${translate("characters")}`
        );
        return false;
      }

      return true;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query?.[paramName], toast, translate]
  );

  const applyValue = useCallback(
    (val: any) => {
      if (!checkValue(val)) {
        return;
      }

      const pathname = routesPublic.search();

      setLoading(true);
      if (val) {
        router.push(
          {
            pathname,
            query: { [paramName]: val },
          },
          { scroll: false, locale }
        );
        return;
      }
      const newQuery = { ...query };
      // @ts-ignore
      delete newQuery[paramName];

      router.push(
        {
          pathname: routesPublic.root(),
          query: {},
        },
        { scroll: false, locale }
      );

      setSearchStr("");
    },
    [checkValue, locale, paramName, query, router]
  );

  const onKeyDown = useCallback(
    (e: any) => {
      const val = e.target.value;
      if (e.keyCode === 13 || e.key === "Enter") {
        if (!checkValue(val)) {
          return;
        }

        applyValue(val);
        ref.current?.blur();
      }
    },
    [applyValue, checkValue]
  );

  const onClear = useCallback(
    (e: any) => {
      e.stopPropagation();
      e.preventDefault();
      setSearchStr("");
      // setShow(false);
      if(typeof query[paramName] !== "undefined") {
        // applyValue(null);
      }
      ref.current?.focus();
    },
    [paramName, query]
  );

  useEffect(() => {
    if (show) {
      ref.current?.focus();
      setLoading(false);
    }
  }, [show]);

  useEffect(() => {
    if (!loading && query[paramName] === searchStr) {
      setShow(false);
      setLoading(false);
      ref.current?.blur();
    }
  }, [loading, paramName, query, searchStr]);

  return (
    <div className={styles.ButtonGlobalSearch}>
      <div className={cn(styles.back, (!mobile || !show) && styles.hidden)} onClick={() => setShow(false)} />
      {
        mobile && (
          <PublicIcons
            name="search"
            width={28}
            height={28}
            className={cn(styles.mobileButton, show && styles.hidden)}
            onClick={() => setShow(true)}
          />
        )
      }
      <div className={cn(styles.wrapInput, mobile && styles.mobile, mobile && !show && styles.hidden)}>
        {mobile && <div className={cn(styles.iconBack, mobile && styles.mobile)} onClick={() => setShow(false)}>
          <InlineIcons.ArrowShortLeft width={24} height={24} />
        </div>}
        <input
          ref={ref}
          name="global-search"
          type="search"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          placeholder={translate("Search")}
          value={searchStr}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
        {searchStr && !loading && (
          <div className={cn(styles.clear, mobile && styles.mobile)} onClick={onClear}>
            <PublicIcons name="close2" alt="clean" width={24} height={24} />
          </div>
        )}
        <>{loading && <LoaderCircle className={cn(styles.loader, mobile && styles.mobile)} size={20} stretchToSpace color='#0D7D8B' />}</>
        <Button
          className={cn(styles.buttonNew, mobile && styles.mobile, loading && styles.loading)}
          classNameIcon={styles.iconNew}
          color="primary"
          variant="text"
          onClick={() => applyValue(searchStr)}
          icon={IconSearch}
          aria-label={translate("Search")}
        />
        {/*{isMobileSize ? (*/}
        {/*  <>{loading && <LoaderCircle className={cn(styles.loader, mobile && styles.mobile)} size={20} stretchToSpace />}</>*/}
        {/*) : (*/}
        {/*  <Button*/}
        {/*    className={styles.button}*/}
        {/*    classNameText={styles.buttonText}*/}
        {/*    classNameIcon={cn(styles.buttonIcon, loading && styles.loading)}*/}
        {/*    classNameLoader={cn(styles.buttonLoader, loading && styles.loading)}*/}
        {/*    color="primary"*/}
        {/*    onClick={() => applyValue(searchStr)}*/}
        {/*    icon={IconSearch}*/}
        {/*    loading={loading}*/}
        {/*  >*/}
        {/*    {translate("Search")}*/}
        {/*  </Button>*/}
        {/*)}*/}
      </div>
    </div>
  );
};

export default ButtonGlobalSearch;
